import { myMixin } from '../../../mixins.js'
import { mapActions, mapState } from 'vuex'
import state from '../../auth/store/state'

export default {
    mixins: [myMixin],
    data (vm) {
        return {
            title: 'Professores das Turmas',
            search: '',
            dialog: false,
            loadingRefresh: false,
            loadingFilter: false,
            showFormGridPortfolio: false,
            loadingLock: false,
            approximate: false,
            optionsFilter: [
                { id: 1,    field: 'id',                text: 'ID Grade' },
                { id: 2,    field: 'code',              text: 'Código Grade' },
                { id: 3,    field: 'group',             text: 'Turma' },
                { id: 4,    field: 'group.course',      text: 'Curso' },
                { id: 5,    field: 'group.campus',      text: 'Campus' },
                { id: 6,    field: 'discipline',        text: 'Disciplina' },
                { id: 7,    field: 'teacher',           text: 'Professor' },
                { id: 8,    field: 'subGroup',          text: 'SubTurma' },
                { id: 9,    field: 'join',              text: 'ID Junção' },
                { id: 10,   field: 'attributions',      text: 'ID Atribuição' },
                { id: 11,   field: 'group.baseGroups',  text: 'Turma/Cod. Composição' },
            ],
            optionFilter: { id: 3, field: 'group', text: 'Turma' },
            panel: [],
            showDowloadSheet: false,
            showReplicateForm: false,
            readonly: false,
            modelGroups: [],
            item: null,
            itemJoin: null,
            payloadFilter: null,
            showAddTeacher: false,
            itemSelected: null,
            showFormAttribution: false,
            snackbar: false,
            params: {},
            meta: {},
            color: '',
            message: '',
            errors: [],
            loading: false,
            loadingSaving: false,
            loadingSavingHiring: false,
            progress: false,
            showDelete: false,
            showJoin: false,
            y: 'top',
            x: 'right',
            mode: '',
            timeout: 6000,
            indicationStart: state.acesso.periodo.indication_start,
            indicationEnd: state.acesso.periodo.indication_end,
            headers: [
                { text: 'ID', value: 'id' },
                { text: 'Campus', align: 'left', value: 'group.campus.name' },
                {
                    text: 'Curso',
                    align: 'left',
                    filterable: true,
                    value: 'group.course.name',
                    maxwidth: 10
                },
                { text: 'Turno', align: 'left', value: 'group.shift.name' },
                { text: 'Turma', align: 'left', value: 'group', width: '130px', sortable: false, },
                { text: 'Disciplina', align: 'left', value: 'discipline_name',  sortable: false, },
                { text: 'Carga Horária', align: 'left',value: 'work_load' },
                { text: 'Professor', align: 'left',value: 'teacherName',  sortable: false, },
                { text: 'Ações', align: 'right', value: 'actions', sortable: false },
                
            ],
            items: []

        }
    },

    watch: {
        
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData();
            }
        },

    },

    mounted() {

        this.setTitle();

    },

    computed: {
        ...mapState('auth', ['acesso', 'user']),
      },

    methods: {
        ...mapActions('indication', ['ActionGetGridJoin']),
        ...mapActions('grid', ['ActionFindGrids', 'ActionUpdateHiring', 'ActionGridLockAttribution']),
        ...mapActions('attribution', ['ActionDeleteAttribution']),
        
        setTitle(){
            this.title = 'Professoes das turmas [Período de atribuição: ' + state.acesso.periodo.indication_start + ' a ' + state.acesso.periodo.indication_end + ']';
        },

        getData(isSearch = false){
            
            this.loading = isSearch;
            this.progress = !isSearch;
            this.showAddTeacher = false;
            this.showFormAttribution = false;
            this.dialog = false;
            let payload = this.params;

            delete payload.filter_relations;
            delete payload.find_columns;

            if(isSearch){
                payload.page = 1;
            }

            payload.filter_relations = [
                {
                    name: 'workLoadType',
                    key: 'has_teacher', 
                    value: 1,
                },
                {
                    name: 'group',
                    key: 'status_group_id', 
                    value: '1,2',
                    clause: 'whereIn'
                },
            ];

            if(this.payloadFilter){
                
                if(this.payloadFilter.filter_relations){
                    this.payloadFilter.filter_relations.forEach(f => {
                        payload.filter_relations.push(f);
                    });
                }

                if(this.payloadFilter.find_columns){
                    payload.find_columns = this.payloadFilter.find_columns;
                }
            }

            if(!payload.find_columns){
                payload.find_columns = {};
            }

            let newPayload = {};
            
            if(this.isAnima() && payload.search && this.optionFilter){
                payload.operator    = this.approximate ? 'LIKE' : '=';
                Object.assign(newPayload, payload);
                Object.assign(newPayload, { search: this.optionFilter.field + ' := ' + payload.search  });
            }else{
                newPayload = payload;
            }

            let withBase = 'attributions:id,grid_id,teacher_id,attribution_status_id,date_start,date_end,was_exported,was_accepted|attributions.teacher:id,name|group:id,name,campus_id,course_id,time_course,category_group_id,group,shift_id,lock_attribution|group.category:id,name,allow_editing_grids|join:join_id,grid_id,main|teacher:id,name|group.campus:id,name,abbreviation|group.course:id,name|group.shift:id,name|discipline:id,code,name|workLoadType:id,name,initials|subGroup:id,name';
            
            if(this.isAnima()){
                newPayload.with = withBase + '|join.joinBase.category:id,name';
            }else{
                newPayload.with = withBase + '|workTimeDate:id,name,classes_start,classes_end|specificity:id,name|attributions.activityItems:id,name|category:id,name,default,initials';
            }

            newPayload.columns = 'id,discipline_id,was_exported,in_hiring,group_id,specificity_id,work_load,work_load_time,work_load_pay,work_load_type_id,lock_attribution,work_time_date_id,teacher_number,group_number,grid_category_id,sub_group_id';
            newPayload.noResource = true;
            

            this.ActionFindGrids(newPayload)
            .then((res) => {
                this.items = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.progress = false;
                this.panel = null;
                this.loadingRefresh = false;
            });

        },

        async getGridById(grid, loadProgress = true){

            
            this.progress = loadProgress;
           
            var payload = {
               with: 'workTimeDate,teacher,group.campus,group.course,group.shift,group.category,group.baseGroups,discipline,workLoadType,join,shift,attributions.status,attributions.user,attributions.teacher',
               find_columns: {
                   id: grid.id
               }
           }

            await this.ActionFindGrids(payload)
                .then((res) => {
                    this.item = res.data[0];
                    this.item.join = grid.join;
                })
                .finally(() => {
                    this.progress = false;
                });

        },

        addTeacher(item){

            this.itemSelected = item;
            this.showAddTeacher = true;

        },

        edit(grid){
            
            if(grid.join && !grid.joins){
                this.carregarJuncoes(grid).then((obj) => {
                    this.loadGrid(grid);
                });
            }else{
                this.loadGrid(grid);
            }
        },

        loadGrid(grid){
            
            this.getGridById(grid).then((res) => {
                this.showFormAttribution = true;
            });
            
        },

        getAttribution(item, status)
        {
            let find = item.attributions.find(a => a.attribution_status_id == status);
            let att = null;

            if(find){
                
                let teacher = item.in_hiring ? { id: 0, name: 'A Contratar'} : (find.teacher ? find.teacher : { id: 0, name: null });
                
                att = {
                    id:             find.id,
                    teacher:        teacher,
                    date_start:     find.date_start,
                    date_end:       find.date_end,
                    //activityItems:  find.activityItems,
                };
            }else{
                if(item.in_hiring){
                    return {
                        id:             0,
                        teacher:        { id: 0, name: 'A Contratar'},
                        date_start:     null,
                        date_end:       null,
                        //activityItems:  [],
                    };
                }
            }

            return att;
        },
        
        getTextoGrade(){
            
            if(this.item){
                
                var texto = '';
                
                texto = this.item.group.course.name;
                texto += ' | ' + this.item.discipline.name;
                texto += ' | ' + (this.item.teacher ? this.item.teacher.name : "A Definir");
                
                return texto;
            }else
                return "";
           
        },

        perguntarDeletar(grade){
            
            this.item = grade;

            if(grade.join && !grade.joins){
                
                this.carregarJuncoes(grade).then(() => {
                    this.showDelete = true;
                });

            }else{
                this.showDelete = true;
            }
        },

        isExported(item){
            return item.attributions.find(i => (i.attribution_status_id == 1 && i.exported)) != null;
        },

        filter(payload){
            this.payloadFilter = payload;
            this.getData(true);
        },

        verTurmasJuncao(item){
            
            this.itemJoin = item;
            this.showJoin = false;

            if(!item.joins){
                
                this.carregarJuncoes(item).then(() => {
                    this.showJoin = true;
                });
            
            }else{
                this.showJoin = true;
            }
            
        },

        lockAttribution(item){
            
            var payload = {
                id:     item.id,
            }

            this.loadingLock = true;
            this.itemSelected = item;

            this.ActionGridLockAttribution(payload)
                    .then((res) => {
                        this.message = res.message;
                        this.getData(false);
                    })
                    .catch((error) =>{
                        this.error      = true;
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loadingLock = false;
                    });

            
        },

        async carregarJuncoes(item){
            
            var payload = {
                id:     item.join.join_id,
                with: "grids.join,grids.discipline,grids.group,grids.group.campus,status,grids.workLoadType,grids.workTimeDate",
                showAllCampuses: true
            }

            this.progress = true;

            await this.ActionGetGridJoin(payload).then((obj) => {
                this.progress = false;
                item.joins = obj.data.grids;
                this.replicarJuncoes(item.joins);
            });
        },

        async deleteAttribution(){
            
            this.errors = [];
            this.loadingSaving = true;
            
            await this.getGridById(this.item, false);

            let att = this.item.attributions.find(i => i.attribution_status_id == 1);

            if(!att){
                att = this.item.attributions.find(i => i.attribution_status_id == 5);
            }

            if(att){
                this.ActionDeleteAttribution({id: att.id})
                    .then((res) => {
                        this.getData(false);
                    })
                    .catch((error) =>{
                        this.color = 'error';
                        this.message = null;
                        this.errors = error.errors;
                        this.snackbar = true;
                    })
                    .finally(() => {
                        this.showDelete = false;
                        this.loadingSaving = false;
                    });
            }else if(this.item.in_hiring){
                
                this.ActionUpdateHiring({id: this.item.id, in_hiring: 0 })
                    .then((res) => {
                        this.getData(false);
                        this.message = res.message;
                        this.$emit('onCompleted');
                    })
                    .catch((error) =>{
                        this.error      = true;
                        this.errors     = error.errors;
                        this.message    = error.message;
                        this.$emit('onError', error);
                    })
                    .finally(() => {
                        this.showDelete = false;
                        this.loadingSaving = false;
                    });
            }
            
        },

        onError(error){
            
            this.color = 'error';
            this.message = null;
            this.errors = error.errors;
            this.snackbar = true;

        },

        indicarJuncoes(item, teacher){
                
            this.items.forEach((item2) => {

                if(item2.join && (item2.join.join_id == item.join.join_id)){
                    item2.teacher = teacher;
                }
            });
        },

        replicarJuncoes(grids){

            this.items.forEach((item) => {
                
                if(item.join){
                    
                    grids.forEach(j => {
                        if(j.join.grid_id == item.id){
                            item.joins = grids;
                        }
                    });

                }
            });

        },
        
    },
}